.services__container{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem;
    margin-top: 3rem;
    
}
.services-content{
    width: 100%;
    height: 100%;
    border: 1px solid #4faddb;
    border-radius: 2rem;
    background-color: var(--color-primary);
    padding: 1.3rem;    

}
.services-content img{
    border-radius: 1rem;
    border: 1px solid #4faddb;
    height: 50%;
}
.services-content h4{
    padding: 1rem 0 1rem 0;
    color: #FFF;
}
.services-content p{
    color: #FFF;

}
.services__btn{
    width: fit-content;
    margin: 0 auto;
    margin-top: 1rem;
    padding: 0.9rem 2rem;
    background:  #4faddb;
    color: #FFF;
    display: flex;
    margin-bottom:-2rem;
    border-radius: 20px;
    transition: var(--transition);
}

.services__modal{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(71, 69, 69, 0.1);
    display: flex;
    z-index: 9;
    justify-content: center;
    align-items: center;
    padding: 20rem 0rem 15rem 0;
    opacity: 0;
    visibility: hidden;
    
}
.services__modal-content{
    width: 900px;
    position: relative;
    background-color: #FFF;
    padding: 4.5rem 2.5rem 2.5rem;
    border-radius: 1.5rem;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0.188em 1.550em var(--color-primary);
    
}

.services__modal-content p{
    color:var(--color-primary);
}

.services__modal-close{
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    font-size: 1.5rem;
    color: var(--color-primary);
    cursor: pointer;
}
.services__modal-services{
    row-gap: 0.75rem;
}
.services__modal-title{
    color: #4faddb;
    text-align: center;
    justify-content: center;
}

.services__modal-service{
    display: flex;
    align-items: center;
    
    
}

.services__modal-info{
    padding: 0 3rem;
    margin: 1rem;

}
.active-modal{
    opacity: 1;
    visibility: visible;
}

.our__service{
    background: var(--color-primary);
    border-radius: 0 0 2rem 2rem;
    border: 1px solid var(--color-gray-100);
    transition: var(--transition);
    height: 100%;
}
.service__head{
    background: #4faddb;
    padding: 2rem;
    border-radius: 0 0 2rem 2rem;
    box-shadow: 0 2rem 1rem rgba(0,0,0,0.1);
}
.service__head h3{
    color:rgb(245, 242, 236);
    font-size: 1rem;
    text-align: center;

}
.service__list{
    padding: 2rem;
    color: #FFF;

}
.service__list p{
    color: #FFF;
}
.service__list li{
    display: flex;
    gap: 1rem;
    margin-bottom: 0.8rem;
}
.service__list-icon{
    color:#4faddb;
    
}
.service__list-icon a{
    background: #FFF;
    padding: 0.7rem;
    border: 1px solid transparent;
    border-radius: 0.6rem;
    transition: var(--transition);
}
.service__list-image{
    border-radius: 1.5rem;
    overflow: hidden;
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;

}
.dialog__container{
    width: 700px;
    height: 700px;
    border-radius: 12px;
    background-color: var(--color-primary);
    box-shadow: rgba(0,0,0,0.35);
    display: flex;
    flex-direction: column;
    padding: 25px;
}
.dialog__container .title{
    display: inline;
    margin-top: 5px;
    color: #FFF;
}
.titleCloseBtn{
    display: flex;
    justify-content: flex-end;
}
.titleCloseBtn button{
    background-color: transparent;
    color: #FFF;
    border: none;
    font-size: 25px;
    cursor: pointer;
}
.dialog__container .body{
    flex: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    

}
.dialog__container .image{
    height: 700px;
    align-items: center;
    border-radius: 1.5rem;
    overflow: hidden;
    margin-top: 1.5rem;
}



.popup__container{
    width: 700px;
    height: 700px;
    border-radius: 12px;
    background-color: var(--color-primary);
    box-shadow: rgba(0,0,0,0.35);
    display: flex;
    flex-direction: column;
    padding: 25px;
}
.services__modal-content .title{
    display: inline;
    margin-top: 5px;
    color: #FFF;
}
.titleCloseBtn{
    display: flex;
    justify-content: flex-end;
}
.titleCloseBtn button{
    background-color: transparent;
    color: #FFF;
    border: none;
    font-size: 25px;
    cursor: pointer;
}
.services__modal-content .body{
    flex: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    

}
.services__modal-content .image{
    height: 700px;
    align-items: center;
    border-radius: 1.5rem;
    overflow: hidden;
    margin-top: 1.5rem;
}



/*MEDIA QUERIES (medium screen)*/

@media screen and (max-width:1024px) {
    .service__container{
        grid-template-columns: repeat(2,1fr);        
    }
    .services-content{
      width: 300px;
      height: 560px;
      margin-left: 0;
    }
    .our__service{
        height: auto;
        width: 270px;
        
        
    }  
    
}
/*MEDIA QUERIES (small screen)*/

@media screen and (max-width:600px) {
    .service__container{
        grid-template-columns: 100%;
        
    }     
    .services-content{
     display: none;
    }
    
   
    
}