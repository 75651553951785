.about__container{
    margin-top: 1rem;
}
.about__head{
    justify-content: center;

}
.mission-list{
    list-style-type: disc;
}
.about-content p{
    color: gray;
}
.about-content h1{
    color: var(--color-primary);
}
.about-content h4{
    color: var(--color-gray-400);
}
li{
    color: gray;
}


/*MEDIA QUERIES (medium screen)*/

@media screen and (max-width:1024px) {
    .about__container{
        width: 100%;
    }
    
    
}
/*MEDIA QUERIES (small screen)*/

@media screen and (max-width:600px) {
    .about__container{
        width: 100%;
    }
    
}