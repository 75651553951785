
.solution__container{
    margin-top: 4rem;
}
.solution__head{
    justify-content: center;

}
.solution-content{
    margin-top: 2rem;
}

h1{
    color: var(--color-primary);
    
}
p{
    color: gray;
    text-align: justify;

}


/*MEDIA QUERIES (medium screen)*/

@media screen and (max-width:1024px) {
    .service__container{
        width: 100%;

    }
    
}
/*MEDIA QUERIES (small screen)*/

@media screen and (max-width:600px) {
    .service__container{
        width: 100%;

    }
    
    
}