.main__heade {
    width: 100vh;
    height: calc(100vh -3rem);
    display: grid;
    place-items: center;
    margin-top: 3rem;
}

.main__header-container {
    height: 700px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: space-between;
    margin-top: 5rem;
    
}

.main__header-left {
    margin-top: -3rem;
}

/*.main__header-left h4{
    margin-bottom: 1rem;
    font-weight: 400;
    color:rgb(194, 17, 179);
}*/
.main__header-left h1 {
    color: rgb(107, 32, 151);
    font-weight: bold;
}

.main__header-left p {
    margin: 1rem 0 3.5rem;
    font-size: 1.1rem;
    color: rgb(29, 27, 28);
    font-weight: 400;
}

.main__header-right {
    display: flex;
}

.figures{
    display: flex;
    gap: 2rem;
    margin-top: 0.5rem;
    margin-bottom: 2rem;
    background: var(--color-gray-100);
    width: fit-content;
    padding: 0.7rem;
    border: 1px solid transparent;
    border-radius: 0.6rem;
    transition: var(--transition);
    
}
.figures div{
    display: flex;
    flex-direction: column;
}
.figures div h4{
    color: var(--color-primary);
    font-size: 2rem;

}
.figures div small{
    color: var(--color-gray-400);
    text-transform: uppercase;

}




.table__head {
    justify-content: center;
    margin-top: 8rem;
}

.table__container {
    margin-top: 3rem;
}

.btn-log {
    width: 10%;
    margin-top: 3rem;
    margin-left: 40%;
    padding: 0.9rem 2rem;
    background: rgb(28, 28, 97);
    color: #FFF;
    border-radius: 1.5rem;
    transition: var(--transition);
}


/*====Testimonials===*/

.testimonials {
    width: 50rem;
    margin-inline: auto;
}

.testimonials__head {
    justify-content: center;

}

.card.testimonial {
    position: relative;
    text-align: left;
    margin-top: 5rem;

}

.testimonial__avatar {
    width: 4rem;
    height: 4rem;
    border-radius: 1.5rem;
    overflow: hidden;
    position: absolute;
    border: 2px solid transparent;
    top: -2rem;
    left: calc(50% - 2rem);
    box-shadow: 0 1.5rem 2rem rgb(26, 26, 54, 0.4);
    transition: var(--transition);
}

.testimonial:hover.testimonial__avatar {
    border-radius: 50%;
    border-color: var(--color-gray-400);
}

.small h5 {
    color: #fff;
}

.testimonial__quote {

    color: #fff;
    font-style: italic;
    margin: 1rem 0;
}

.card small .testimonial__title {
    margin-top: 0.1rem;

}

.testimonials__btn-container {

    width: fit-content;
    margin: 2.5rem auto 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.testimonials__btn {
    background: transparent;
    cursor: pointer;

}

.testimonials__btn svg {
    fill: #4faddb;
    font-size: 1.8rem;
    width: 3rem;
    height: 3rem;
}

/*====Solution===*/



/*====Solution===*/


.solution__container {
    margin-top: 4rem;
}

.solution__head {
    justify-content: center;

}

.solution__wrapper {
    display: grid;
    grid-template-columns: 30% 60%;
    gap: 3rem;
    margin-top: 4rem;
}

.side__list .btns .sd {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    position: fixed;
    border: 1px solid var(--color-primary);
    border-radius: 0.6rem;
    color: #FFF;
    cursor: pointer;

}

span {
    color: var(--color-primary);
    font-weight: bold;

}

/*span:hover {
    color: #FFF;
    padding: 1rem 2rem 1rem;
    border-radius: 0 1rem 1rem 0;
    background-color: var(--color-primary);
}*/

.solution-right {
    background-color: var(--color-primary);
    border-radius: 1em 0 1rem 0;
    box-shadow: 0 0.188em 1.550em rgba(156, 156, 156);
}

.icon {
    margin-top: -1.5rem;
    width: 10%;
    height: 50px;
    color: #FFF;
    background-color: #4faddb;
    margin-left: 5%;
    border-radius: 0.4rem;
}

.title h1 {
    color: #4faddb;
    margin-left: 40%;
}

.body p {
    color: #FFF;
    justify-content: center;
    align-items: center;
    padding: 1rem 2rem 2rem 3rem;
    font-weight: bold;


}

/*===Contact===*/

.contact__container {
    width: 58%;
    display: grid;
    margin-top: 5rem;
    grid-template-columns: 30% 58%;
    gap: 12%;

}

.contact__options {
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 1rem;

}

.contact__option {
    background: var(--color-primary);
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    color: #FFF;
    border: 1px solid transparent;
    transition: var(--transition);
}

.contact__option-icon {
    font-size: 1.5rem;
    margin-bottom: 0.3rem;
    text-align: center;

}

form {

    display: flex;
    flex-direction: column;
    margin-top: 4rem;
    gap: 1.2rem;
}

input {
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid var(--color-primary);
    resize: none;
}

textarea {
    width: 90%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid var(--color-primary);
    resize: none;
}

.contact__btn {
    width: fit-content;
    margin: 0 auto;
    padding: 0.9rem 2rem;
    background: rgb(28, 28, 97);
    color: #FFF;
    margin-top: 2rem;
    border-radius: 1.5rem;
    transition: var(--transition);

}



/*====Footer===*/

footer {
    background: var(--color-primary);
    margin-top: 5rem;
    padding-top: 5rem;
    font-size: 0.9rem;
    color: var(--color-gray-100);
}

.footer__container {
    display: grid;
    grid-template-columns: 26rem 1fr 1fr 1fr;
    gap: 6rem;
}

.footer__container article {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.7rem;
}

.footer__container article .logo {
    width: 200px;
    border: 1px solid transparent;
    border-radius: 0.6rem;
    transition: var(--transition);
    cursor: pointer;


}

.footer__container article p {
    margin-top: 0.1rem;
    color: #FFF;
}

.footer__container article h4 {
    margin-bottom: 0.6rem;
    font-size: 1rem;
}

.footer__container article .footer__link {
    cursor: pointer;
    color: #fff;
}

.footer__socials {
    margin-top: 1.5rem;
    display: flex;
    gap: 1rem;
    align-items: center;
}

.footer__socials a {
    background: var(--color-gray-100);
    padding: 0.7rem;
    border: 1px solid transparent;
    border-radius: 0.6rem;
    transition: var(--transition);
}

.footer__socials a:hover {
    border-color: var(--color-gray-100);
    background: transparent;
}

.footer__copyright {
    color: #FFF;
    text-align: center;
    padding: 1.5rem 0;
    border-top: 2px solid #FFF;
    margin-top: 5rem;

}

/*Scroll To Top*/
.scroll-btn {
    position: fixed;
    bottom: 15px;
    right: 20px;
    height: 40px;
    width: 40px;
    border: 1px solid #FFF;
    border-radius: 0.6rem;
    background-color: var(--color-primary);
    color: #4faddb;
    cursor: pointer;
}


/* Modal Button */
.modalButton {
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    padding: 12px 24px;
}




/*==== MEDIA QUERIES (medium screen)*/

@media screen and (max-width:1024px) {
    .main__heade{
        height: fit-content;        
    }
    .main__header-container{    
        margin-top: 5rem;
    }    
    .contact__container {
        grid-template-columns: 1fr;
        align-items: center;
        justify-content: center;
    }
    .contact__option {
        margin-left: 50%;
        width: 60%;        
    }
    .contact__btn{
        margin-bottom: 3rem;
    }
    .testimonials {
        width: 80%;
    }
    .footer__container {
        grid-template-columns: 1fr 1fr;
        gap: 4rem;
        margin-top: 2rem;
    }
    .table__container {
        width: fit-content;
    }
}

/* MEDIA QUERIES (medium screen*/
@media screen and (max-width:600px) {
    .main__heade{
        margin-top: 0;
        padding: 12rem 0;
        height: 100vh;
    }
    .main__header-container{    
        margin-top: 10rem;
    }
    .main__header-right{
        display: none;
    }
    .main__header-container{
        grid-template-columns: 1fr;
    }
    .testimonials {
        width: 100%;
    }
    
    .contact__container {
        grid-template-columns: 1fr;
        gap: 1.5rem;
        margin-top: 2rem;
        align-items: center;
        justify-content: center;
    }

    .contact__options {
        display: none;

    }

    .footer {
        margin-top: 8rem;
    }

    .footer__container {
        grid-template-columns: 1fr;
        gap: 3rem;
    }

    .footer__container article {
        align-items: center;
    }

    .footer__container article p {
        text-align: center;
    }

    .table__container {
        width: 100%;

    }
}