.header {
    position: fixed;
    height: 80px;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    transition: .3s ease-in;
    overflow: hidden;
    border: 1px solid var(--color-gray-100);
    background-color:var( --color-white);
}

.header .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1240px;
    margin: auto;
    height: 100%;
    padding: 0 1rem;
    
}

.header .nav-menu a {    
    color: var(--color-primary);
}

.header .navbar img {
    width: 260px;
    height: 78px;
    padding: 2px 2px 2px 1px;
    cursor: pointer;
    
   
}

.header .nav-menu {
    display: flex;
    cursor: pointer;
}

.header .nav-item {
    padding: 1rem;
    font-weight: 500;
}
.nav-item .active{
    padding-bottom: 12px;
    border-bottom: 3px solid #4faddb;
}

.header .nav-item a:hover {
    padding-bottom: 12px;
    border-bottom: 3px solid #0361a0;
}

.hamburger {
    display: none;

}
.nav-right{
    text-align: right;    
    
}


@media screen and (max-width:1024px) {
    
    .header {
        max-width: 100%;
        background-color: var(--color-primary);

    }

    .header .navbar {
        max-width: 100%;
        background: transparent;
        font-size: 1.8rem;
        
    }

    .hamburger {
        display: inline-block;
        color: var(--color-primary);
        cursor: pointer;
        
    }

    .nav-menu {
        position: fixed;
        right: -95%;
        top: 90px;
        flex-direction: column;
        background-color: #FFF;
        width: 50%;
        height: fit-content;
        text-align: center;
        gap: 0;
        
    }

    .nav-menu.active {
        right: 5rem;
        margin-top: 1%;
        
    }

    .header .navbar img {
        width: 300px;
    }
}
@media screen and (max-width:600px){
    .header {
        max-width: 100%;
        background-color: var(--color-primary);

    }

    .header .navbar {
        max-width: 100%;
        background: transparent;
        font-size: 1.8rem;
        
    }

    .hamburger {
        display: inline-block;
        color: var(--color-primary);
        cursor: pointer;
        
    }

    .nav-menu {
        position: fixed;
        right: -95%;
        top: 90px;
        flex-direction: column;
        background-color: #FFF;
        width: 50%;
        height: fit-content;
        text-align: center;
        gap: 0;
        
    }

    .nav-menu.active {
        right: 5rem;
        margin-top: 1%;
        
    }

    .header .navbar img {
        width: 280px;
    }
}
















